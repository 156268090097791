import React from 'react';
import { camelizeKeys } from 'humps';
import { shape } from 'prop-types';
import { graphql } from 'gatsby';
import { isEmpty } from 'lodash';

import Layout from '../../components/Layout';
import DynamicZone from '../../components/DynamicZone';

const News = ({ data, pageContext }) => {
  const pageData = camelizeKeys(data.strapi.new);
  const headerData = data.strapi && data.strapi.header;
  const footerData = data.strapi && data.strapi.footer;
  const signUpData = data.strapi && data.strapi.signUp;

  return (
    <Layout headerData={headerData} footerData={footerData} signUpData={signUpData}>
      {pageData && !isEmpty(pageData.content) && (
        <DynamicZone
          components={pageData.content}
          data={{
            date: pageData.date,
            pageContext,
            backgroundText: pageData.backgroundText,
            relatedEvents: pageData.relatedEvents && pageData.relatedEvents.slice(0, 2),
            relatedEventsButton: pageData.pageData,
            socialLinks: pageData.socialLinks && pageData.socialLinks.slice(0, 3),
            mediaContentUrl: pageData.mediaContentUrl,
            mobileEventBanner: pageData.mobileEventBanner,
            type: pageData.type,
          }}
        />
      )}
    </Layout>
  );
};

News.propTypes = {
  data: shape({
    strapi: shape(),
  }),
  pageContext: shape().isRequired,
};

News.defaultProps = {
  data: {
    strapi: {},
  },
};

export default News;

export const newsQuery = graphql`
  query getNewsPage($id: ID!) {
    strapi {
      signUp {
        title
        label
        buttonLabel
        backgroundImage {
          url
        }
      }
      footer {
        id
        links {
          id
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            path
            label
            openInNewWindow
          }
        }
        socialLinksTitle
        logo {
          id
          url
        }
        socialLinks {
          label
          id
          socialLink {
            path
            label
            logo {
              id
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        linksTitle
      }
      header {
        id
        links {
          id
          isRed
          linkData {
            id
            label
            path
            openInNewWindow
            offerIdEverflow
            data {
              id
              label
              path
              openInNewWindow
            }
          }
        }
        socialLinks {
          id
          label
          socialLink {
            path
            label
            logo {
              url
            }
          }
        }
        logo {
          url
        }
      }
      new(id:$id) {
        backgroundText
        mediaContentUrl
        mobileEventBanner {
          mobileBackground {
            url
          }
          data {
            id
            event {
              label
              data {
                slug
                title
                dateDayOfWeek
                dateMonth
                dateTimezone
                dateClock
                date
                image {
                  url
                }
              }
            }
            bannerLinks {
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                label
                path
                openInNewWindow
              }
            }
          }
        }
        type {
          label
        }
        date
        relatedEvents {
          data {
            id
            description
            title
            slug
            date
            dateClock
            dateDayOfWeek
            dateMonth
            dateTimezone
            image {
              url
            }
          }
        }
        relatedEventsButton {
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            label
            path
            openInNewWindow
          }
        }
        seo {
          title
          id
          description
        }
        socialLinks {
          socialLink {
            id
            path
            logo {
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        content {
          ... on STRAPI_ComponentSectionsNewsContent {
            __typename
            id
            label
            author
            title
            richText
            button {
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                label
                path
                openInNewWindow
              }
            }
          }
          ... on STRAPI_ComponentSectionsBannerWithOneEvent {
            id
            mobileBackground {
              url
            }
            data {
              id
              bannerLinks {
                label
                path
                openInNewWindow
                offerIdEverflow
                data {
                  openInNewWindow  
                  path
                  label
                }
              }
              event {
                label
                data {
                  description
                  title
                  slug
                  id
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
